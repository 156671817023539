import React from "react";
import Damage from "./Damage/Damage";

class ExistingDamagesWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDamages: []
        }

        this.selectedDamage = this.selectedDamage.bind(this);
        this.unselectedDamage = this.unselectedDamage.bind(this);

    }

    selectedDamage(item) {
        let itemId = parseInt(item);

        if (!this.state.selectedDamages.includes(itemId)) {
            this.setState({
                selectedDamages: [...this.state.selectedDamages, itemId]
            }, () => {
                this.props.sendDamages(this.state.selectedDamages);
            });

        }

    }

    unselectedDamage(item) {
        let itemId = parseInt(item);

        if (this.state.selectedDamages.includes(itemId)) {
            this.setState({
                selectedDamages: this.state.selectedDamages.filter(item => item !== itemId)
            }, () => {
                this.props.sendDamages(this.state.selectedDamages);
            });
        }
    }

    render() {
        if (this.props.damages && this.props.damages.length > 0) {
            return this.props.damages.map(item => {
                return (
                    <div>

                        <Damage
                            key={'damage-'+item}
                            orderId={this.state.orderId}
                            damageId={item} {...this.props}
                            selectedItems={this.state.selectedDamages}
                            selectDamage={this.selectedDamage}
                            unselectDamage={this.unselectedDamage}/>
                    </div>
                );
            });
        } else {
            return null;
        }
    }
}

ExistingDamagesWrapper.propTypes = {};

export default ExistingDamagesWrapper;
