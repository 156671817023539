import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Style from "../Damage.module.scss";
import Image from "./Image/Image";
import ConditionalContent from "../../../../../../../../ConditionalContent";
import { deleteImage, getImage } from "../../../../../../../../utils/utils";
import FsLightbox from "fslightbox-react";

class DamageImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: this.props.images,
            files: [],
            setFiles: [],
            damageImagePaths: this.props.damageImagePaths,
            lightboxImages: this.props.lightboxImages,
        };

        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

    }


    static getDerivedStateFromProps(props, current_state) {
        if (current_state.images !== props.images) {
            return {
                images: props.images
            }
        }
        return null
    }

    handleDragStart = (event) => {
        event.dataTransfer.setData("text/plain", event.target.id)
    }

    handleDragOver = (event) => {
        event.preventDefault()
    }

    handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            console.log(files);
            this.props.directDragAndDropImageUpload(files);
        }
    };

    render() {
        var damageImages = [];
        var damageImagesPaths = [];
        var open = false;
        const srlOptions = {
            buttons: {
                showAutoplayButton: false,
                showCloseButton: false,
                showDownloadButton: false,
                showFullscreenButton: false,
                showNextButton: false,
                showPrevButton: false,
                showThumbnailsButton: false,
            },
            thumbnails: {
                showThumbnails: true
            }
        }

        return (

            <SimpleReactLightbox>
                <div className={Style.dmgWrap}>
                    <SRLWrapper elements={this.state.lightboxImages}>
                        {
                            this.state.images.map((image, index) => {
                                return (
                                    <Image {...this.props} key={'img-' + this.props.dmgId + '-' + image} imgId={image} />
                                )
                            })
                        }
                    </SRLWrapper>
                </div>

                <ConditionalContent check={this.props.allowUpload}>
                    <div>
                        <div>
                            <div className={"aresDragAndDrop"}>
                                <div>
                                    <div className="" onDragOver={this.handleDragOver} onDrop={this.handleDrop} >
                                        <div className="" style={{
                                            display: "swal2-file"
                                        }} draggable="true" onDragStart={this.handleDragStart}>
                                            <div className='file-upload-div'>
                                                <ul style={{
                                                    paddingLeft: "0px"
                                                }}>
                                                    ... drop files here
                                                    {this.state.files.map((file, index) => (
                                                        <li key={index}>{file.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ConditionalContent>

            </SimpleReactLightbox>


        )


        if (this.state.lightboxImages && this.state.lightboxImages.length > 0) {
            return (
                <div className={Style.dmgWrap}>
                    {
                        this.state.lightboxImages.map((image, index) => {

                            return (
                                <img width="280px" src={image.src} onClick={
                                    () => {
                                        if (!this.state.open) {
                                            this.setState({
                                                open: true
                                            })
                                        }


                                    }
                                } />

                            );



                        })


                    }







                </div>

            );
        } else {
            if (this.props.allowUpload) {
                return (
                    <div>
                        <div>
                            <div className={"aresDragAndDrop"}>
                                <div>
                                    <div className="" onDragOver={this.handleDragOver} onDrop={this.handleDrop} >
                                        <div className="" style={{
                                            display: "swal2-file"
                                        }} draggable="true" onDragStart={this.handleDragStart}>
                                            <div className='file-upload-div'>
                                                <ul style={{
                                                    paddingLeft: "0px"
                                                }}>
                                                    ... drop files here
                                                    {this.state.files.map((file, index) => (
                                                        <li key={index}>{file.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                );
            } else {
                return null;
            }
        }

    }
}

DamageImages.propTypes = {};

export default DamageImages;
