import Axios from "axios";
import { saveAs } from 'file-saver';
import React from "react";
import Store from "store";
import {
    deleteCargoItem,
    generateRandomId
} from "../../../../../utils/utils";

import Style from "./CargoItem.module.scss";
import DamagesWrapper from "./DamagesWrapper/DamagesWrapper";
import ExistingDamagesWrapper from "./DamagesWrapper/ExistingDamagesWrapper";

import SimpleReactLightbox from "simple-react-lightbox";
import Inspection from "./Inspection/Inspection";

import ConditionalContent from "../../../../../ConditionalContent";

import { faEdit, faFileImport, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import Attachments from "./Attachments/Attachments";
import GalleryModal from "./GalleryModal/GalleryModal";
const DeleteItemPopup = withReactContent(Swal);

const LoadingModalSwal = withReactContent(Swal);
const otherDamagesImportModal = withReactContent(Swal);


class CargoItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.itemid,
            orderId: this.props.orderid,
            item: null,
            token: Store.get('accessToken').token,
            cargoSet: null,
            carrierOwner: null,
            createdBy: null,
            damages: [],
            otherDamages: null,
            destination: null,
            inspectedBy: null,
            inspectedDate: null,
            inspectors: [],
            make: null,
            model: null,
            modifiedBy: null,
            modifiedOn: null,
            portOfOrigin: null,
            transport: null,
            vin: null,
            weather: null,
            transportData: null,
            damageCount: 0,
            tempDamages: [],
            inspection: null,
            bl: null,
            info: null,
            voyage: null,
            inspectionWeather: '',
            inspectionTemperature: '',
            transportCompany: null,
            show: false,
            otherDamagesShow: false,
            imagesCount: 0,
            orderTransportCompany: null,
            transportId: 0,
            transportIdName: '',
            hideDetails: false,
            bulkSelectedDamages: [],
            transportMode: {},
            damageImageCount: 0,
            itemOtherDamages: [],
            selectedOtherDamagesForImport: []
        };


        this.addNewDamage = this.addNewDamage.bind(this);
        this.markInspected = this.markInspected.bind(this);
        this.displayTransport = this.displayTransport.bind(this);
        this.removeNewDamage = this.removeNewDamage.bind(this);
        this.refreshCargoData = this.refreshCargoData.bind(this);
        this.refreshDamages = this.refreshDamages.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.selectCargo = this.selectCargo.bind(this);
        this.handleScrollToElement = this.handleScrollToElement.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.toggleOtherDamagesShow = this.toggleOtherDamagesShow.bind(this);
        this.updateCount = this.updateCount.bind(this);
        this.fetchCargoData = this.fetchCargoData.bind(this);
        this.pvReportButton = this.pvReportButton.bind(this);
        this.otherDamageImportButton = this.otherDamageImportButton.bind(this);
        this.sendPvReport = this.sendPvReport.bind(this);
        this.checkIfBulkToggleEnabled = this.checkIfBulkToggleEnabled.bind(this);
        this.isImportDamagesToggleEnabled = this.isImportDamagesToggleEnabled.bind(this);
        this.transportMode = this.transportMode.bind(this);
        this.importDamage = this.importDamage.bind(this);
        this.otherDamagesModalContent = this.otherDamagesModalContent.bind(this);
        this.sendDamages = this.sendDamages.bind();
        this.bulkDeleteDamages = this.bulkDeleteDamages.bind();
        this.addDamageForImport = this.addDamageForImport.bind();
        this.getFullDamageByCode = this.getFullDamageByCode.bind(this);

    }

    handleScrollToElement() {
        let element = document.getElementById("item-bot-" + this.props.itemid);
        const yOffset = -500;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    focusElement(damageId) {
        if (!damageId) return false;

        document.querySelector('#damage-' + damageId + ' .dsPart').focus();
    }

    componentDidMount() {

        /*if(this.props.item.transport) {
            getTransport(this.props.item.transport).then(resp => {
                this.setState({
                    transportData: resp
                })
            });
        }*/


        this.fetchCargoData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inspection !== this.props.inspection) {
            this.fetchCargoData();
        }
    }

    checkIfBulkToggleEnabled() {
        if (this.props.selectedCargo.includes(this.props.itemid)) {
            if (this.props.bulkShowCargoItems && !this.state.show) {
                return true;
            } else {
                return false;
            }
        } else {
            return this.state.show;
        }



        return false;
    }

    isImportDamagesToggleEnabled() {
        return this.state.otherDamagesShow;
    }

    getFullDamageByCode(code) {
        let codeItem = parseInt(code);
        console.log("patrik   " + codeItem)
        var damageSet = this.props.damageSetData?.filter(item => {
            
            return String(item.id) === String(codeItem);

        })


        return damageSet.id;
        console.log("DDKDKDKDKDKKDKDKDKDKD");
        console.log(damageSet);
    }

    async fetchCargoData() {
        

        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        config.params = {
            'order_id': this.state.orderId
        }

        

        Axios.get(process.env.REACT_APP_API_BASE + '/items/' + this.props.itemid, config)
            .then(response => {
                if (response.status === 200) {
                    console.log("TUKAJ::: ");
                    console.log(response.data);
                    this.setState({
                        cargoSet: response.data.item.cargoSet,
                        carrierOwner: response.data.item.carrierOwner,
                        createdBy: response.data.item.createdBy,
                        damages: response.data.item.damages,
                        destination: response.data.item.destination,
                        inspectedBy: response.data.item.inspectedBy,
                        inspectedDate: response.data.item.inspectedDate,
                        inspectors: response.data.item.inspectors,
                        inspection: response.data.item.inspection,
                        model: response.data.item.model,
                        make: response.data.item.make,
                        modifiedBy: response.data.item.modifiedBy,
                        modifiedOn: response.data.item.modifiedOn,
                        portOfOrigin: response.data.item.portOfOrigin,
                        transshipmentPort: response.data.item.transshipmentPort,
                        portOfDischarging: response.data.item.portOfDischarging,
                        transport: response.data.item.transport,
                        vin: response.data.item.vin,
                        weather: response.data.item.weather,
                        bl: response.data.item.bl,
                        info: response.data.item.info,
                        other: response.data.item.other,
                        voyage: response.data.item.voyage,
                        transportCompany: response.data.item.transportCompany,
                        transportId: response.data.item.transport_id,
                        imagesCount: response.data.item.images?.length,
                        attachments: response.data.item.reports,
                        item: response.data,
                        transportMode: response.data.item.transport_mode,
                        otherDamages: response.data.numDamagesInOtherOrders,
                    });
                } else {
                    throw new Error('Smt went wrong with fetching cargo items');
                }

                if (this.state.damages) {
                    let damageCount = 0;
                    this.state.damages.map((damage) => {
                        axios.get(process.env.REACT_APP_API_BASE + '/damage/' + damage + '/imagecount', {
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token
                            }
                        }).then(res => {
                            let numberOfDamages = res.data.length;
                            damageCount = damageCount + numberOfDamages;
                            this.setState({
                                damageImageCount: damageCount
                            })
                        })
                    })



                }

                

                if (this.state.transportId) {
                    Axios.get(process.env.REACT_APP_API_BASE + '/transports/' + this.state.transportId, {
                        headers: {
                            'Authorization': 'Bearer ' + this.state.token
                        },
                    }).then(resp => {
                        if(resp.status !== 204) {
                            this.setState({
                                transportIdName: resp.data.transport.field_1
                            })
    
                            console.log(this.state.orderTransportCompany);
                        }
                        

                    })
                }

            }).catch(err => {
                console.log(err);
                /*if (err.response.status === 401) {
                    console.log('attempting reauth');
                }*/
            }).then(() => {
                /*if (this.state.transport) {
                    getTransport(this.state.transport).then(resp => {
                        this.setState({
                            transportData: resp
                        })
                    });
                }*/

            });

            
    }


    addNewDamage() {
        let tempId = generateRandomId(this.props.itemid);
        let tempArr = this.state.tempDamages;
        this.setState({
            show: true
        }, () => {
            this.handleScrollToElement();
        });

        if (!tempArr.includes(tempId)) {
            tempArr.push(tempId);
            this.setState({
                tempDamages: tempArr
            }, () => {
                this.focusElement(tempId);
            });
        } else {
            this.addNewDamage();
        }
    }


    //Removes temp damage from state array tempDamages
    removeNewDamage(tempId) {
        if (!tempId) return;
        let damages = this.state.tempDamages;

        let newDamages = damages.filter(e => {
            return e !== tempId;
        });

        this.setState({
            tempDamages: newDamages
        });
    }

    displayTransport() {

        if (this.state.transportData) {

            return (
                <span>{this.state.transportData.type} / {this.state.transportData.identificationName}</span>
            );
        }
    }

    refreshCargoData() {

        let config = {};
        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/items/' + this.props.itemid, config).then(response => {
            if (response.status === 200) {
                this.setState({
                    otherDamages: response.data.numDamagesInOtherOrders,
                    damages: response.data.item.damages
                }, (response) => {

                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            if (err.response.status === 401) {
                console.log('attempting reauth');
            }
        });
    }

    refreshDamages() {
        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };

            Axios.get(process.env.REACT_APP_API_BASE + '/items/' + this.props.itemid, config).then(response => {
                if (response.status === 200) {
                    this.setState({
                        damages: response.data.item.damages,
                        otherDamages: response.data.numDamagesInOtherOrders
                    }, () => {
                        resolve(response);
                    });
                } else {
                    reject('smt went wrong');
                }
            }).catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    console.log('attempting reauth');
                }
            });
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    markInspected() {
        this.props.markInspected(this.state.id, this.state.orderId);
    }

    handleDelete(e) {
        e.preventDefault();

        DeleteItemPopup.fire({
            title: 'Confirm Deletion',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    return deleteCargoItem(this.props.itemid, this.state.orderId);
                } else {
                    return false;
                }
            })
            .then(resp => {
                if (resp) {
                    if (resp.status === 200) {
                        this.props.refreshItems();
                        Swal.fire(
                            'Deleted!',
                            'Cargo item has been deleted.',
                            'success'
                        );
                    } else {
                        Swal.fire(
                            'Oops!',
                            'Something went wrong.',
                            'error'
                        );
                    }
                }
            });
    }

    selectCargo(e) {
        if (e.target.checked) {
            this.props.selectCargo(e.target.value);
        } else {
            this.props.unselectCargo(e.target.value);
        }
    }

    toggleShow(id) {
        console.log('NEKAJ NEKAJ ')
        this.fetchCargoData();
        this.setState({

            show: !this.state.show,
        }, () => {
            if (this.state.show) {
                this.focusFirstItem(id);
            }
        });
        console.log(this.state.hideDetails + "...props: " + this.props.bulkShowCargoItems + "...show: " + this.state.show);
    }

    async toggleOtherDamagesShow(id) {

        await Axios.get(process.env.REACT_APP_API_BASE + '/orders/' + this.state.orderId + '/items/getAllDamages/' + this.props.vin, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                console.log(res.status);
                this.setState({
                    itemOtherDamages: res.data
                })
            }).catch(error => {
                otherDamagesImportModal.fire({
                    title: 'No other damages found',
                    text: "No damages found in another orders",
                    icon: 'warning',
                });
            });

        //         this.fetchCargoData();
        this.setState({

            otherDamagesShow: !this.state.otherDamagesShow,
        }, () => {
            if (this.state.otherDamagesShow) {
            }
        });
    }

    focusFirstItem(id) {
        let nodes = document.querySelectorAll('#c-item-' + id + ' .dsPart');

        if (nodes && nodes.length > 0) {
            nodes[0].focus();
        }
    }

    updateCount(imagesCount) {
        this.setState({
            imagesCount
        })
    }

    pvReportButton() {
        if (this.state.damages.length > 0) {
            if (this.state.make) {
                if (this.state.make.name === 'Renault' || this.state.make.name === 'Ford' || this.state.make.name === 'Citroen' || this.state.make.name === 'Peugeot') {
                    return <button onClick={() => this.sendPvReport()} className="btn-min text-secondary">Create Damage Protocol Report</button>
                }
            }
        }
    }

    otherDamageImportButton() {
        return <button onClick={(e) => { e?.preventDefault(); this.toggleOtherDamagesShow(this.props.itemid) }} className="btn-min text-secondary">Import damages from another order</button>
    }


    async otherDamagesModalContent() {
        return (<div>djdjjd</div>);

        return (this.state.itemOtherDamages.map((item, index) => {
            return (<tr key={index}>
                <td>{item.ds_part}</td>
                <td>{item.ds_type}</td>
                <td>{item.ds_severity}</td>
                <td>{item.ds_cause}</td>
                <td>{item.ds_repair_mode}</td>
                <td>{item.ds_class}</td>
                <td>{item.ds_dln}</td>
                <td>{item.ds_commentClient}</td>
                <td>{item.ds_commentInspectus}</td>
                <td>{item.ds_dimensions}</td>
                <td>{item.id_number}</td>
            </tr>
        )}));
        console.log(this.state.itemOtherDamages)

        this.setState({})

    }

    importSelectedDamage(item) {

    }

    addDamageForImport(item) {
        this.state.selectedOtherDamagesForImport.push(item)

    }

    sendPvReport() {
        LoadingModalSwal.fire({
            title: 'Please wait. Creating damage report...',
            showLoaderOnConfirm: false,
            showCancelButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/reports/' + this.state.id + '/pv', {}, config)
            .then(res => res.data)
            .then(blob => {
                saveAs(blob, `order-${this.state.id}-images`);
                LoadingModalSwal.close()
            })
    }

    sendDamages = (damages) => {
        console.log(damages);
        this.setState({
            bulkSelectedDamages: damages
        });
    }

    bulkDeleteDamages = () => {
        Axios.post(process.env.REACT_APP_API_BASE + '/damages/bulkdelete', this.state.bulkSelectedDamages, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    Swal.fire(
                        'Deleted!',
                        'Cargo items have been deleted.',
                        'success'
                    ).then(function () {
                        window.location.reload()
                    });
                }
            })
    }
    
    importDamage(damageId) {
        LoadingModalSwal.showLoading()
        Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.orderId + '/items/' + this.state.id + '/copyDamages', [damageId], {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 201 || r.status === 200) {
                LoadingModalSwal.close()
                this.refreshDamages()
                // return resolve();
            } else if (r.status === 422) {
                LoadingModalSwal.close()
            } else {
                LoadingModalSwal.close()
                // return reject('Something went wrong with the api call');
            }

        }).catch( e => {
            if(e.response.status === 422) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Damage already exists",
                  });
                  this.refreshDamages()
            }
            
            
        }
        )
    }

    transportMode() {
        if (this.props.transportType === 'train') {
            console.log('je vlak');
            return (
                <span className={Style.value}>{this.state.transportMode !== null ? this.state.transportMode.field_1 : ''}</span>
            )
        }
        if (this.props.transportType === 'truck') {
            if (this.state.transportMode !== null) {
                return (
                    <span className={Style.value}>{this.state.transportMode.field_1 + ' - ' + this.state.transportMode.field_2}</span>
                )
            }

        }
    }

    render() {
        return (
            <div id={'c-item-' + this.props.itemid} className={'accordion-item card no-shadow'}>
                <div className="card-body p-2">
                    <div>
                        <div>

                            <div className={"row align-items-start mb-2"}>
                                <div className={"col-12 col-md-8"}>
                                    <div className={"d-flex align-items-center"}>
                                        <ConditionalContent className={"d-flex align-items-end"} check={!this.props.hideBulk}>
                                            <div className="form-check form-check-inline me-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={this.props.itemid}
                                                    id={'check-' + this.props.itemid}
                                                    name="cargoCheck"
                                                    checked={this.props.selectedCargo?.includes(this.props.itemid)}
                                                    onChange={this.selectCargo}
                                                />
                                            </div>
                                        </ConditionalContent>
                                        <ConditionalContent check={this.props.itemid} className={Style.inlineMetaWrapper}>
                                            <span className={Style.label}>ID: </span>
                                            <span className={Style.value}>{this.props.itemid}</span>
                                        </ConditionalContent>

                                        <Inspection data={this.props.inspection} allUsers={this.props.allUsers} />

                                        <ConditionalContent check={this.state.damages} className={Style.inlineMetaWrapper + " ms-3"}>
                                            <span className={Style.label}>Damages: </span>
                                            <span className={Style.value}>{this.props.damages ? this.props.damages.length : 0}</span>
                                        </ConditionalContent>

                                        <ConditionalContent check={this.state.otherDamages} className={Style.inlineMetaWrapper + " ms-3"}>
                                            <span className={Style.label}>Damages in another orders: </span>
                                            <span className={Style.value}>{this.state.otherDamages ? this.state.otherDamages : 0}</span>
                                        </ConditionalContent>
                                    </div>
                                    <div>

                                        <span className={Style.vin}>{this.state.vin}</span>

                                        {this.state.make ? <span className={Style.vin}> / {this.state.make.name}</span> : null}
                                        {this.state.model ? <span className={Style.vin}> {this.state.model.name}</span> : null}
                                    </div>
                                    <div className={"has-border"}>
                                        <span className={Style.label}>Transport: </span>
                                        <span className={Style.value}>{this.displayTransport()}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex justify-content-end">
                                    <div>
                                        <div className={"d-flex justify-content-end align-items-center"}>
                                            <button className={"btn-min me-2"} onClick={this.handleDelete}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            <ConditionalContent check={this.props.canEditOrder}>
                                                <Link className={"btn-min"} to={"/dashboard/orders/order/" + this.state.orderId + "/edit-cargo-item/" + this.props.itemid}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                            </ConditionalContent>

                                            <button
                                                className={this.checkIfBulkToggleEnabled() ? "c-item-accordion-btn accordion-button" : "c-item-accordion-btn accordion-button collapsed"}
                                                type="button"
                                                onClick={(e) => { e?.preventDefault(); this.toggleShow(this.props.itemid) }}
                                            />
                                        </div>
                                        <div className={"d-flex justify-content-end"}>
                                            {!this.state.inspection ? <button onClick={() => { this.markInspected() }} className={"btn-min text-secondary me-2"}>Mark inspected</button> : null}
                                            <button onClick={() => { this.addNewDamage(); }} className={"btn-min text-secondary"}>Add new damage</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={Style.meta + " d-flex align-items-start mt-1"}>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Origin: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.portOfOrigin ? this.state.portOfOrigin.code : '/'}</span> : <span className={Style.value}>{this.state.portOfOrigin ? this.state.portOfOrigin.code : '/'}</span>}

                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Transshipment: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.transshipmentPort ? this.state.transshipmentPort.code : '/'}</span> : <span className={Style.value}>{this.state.transshipmentPort ? this.state.transshipmentPort.code : '/'}</span>}

                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Discharging: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.portOfDischarging ? this.state.portOfDischarging.code : '/'}</span> : <span className={Style.value}>{this.state.portOfDischarging ? this.state.portOfDischarging.code : '/'}</span>}
                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Destination: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.destination ? this.state.destination : '/'}</span> : <span className={Style.value}>{this.state.destination ? this.state.destination : '/'}</span>}
                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>BL: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.bl ? this.state.bl : '/'}</span> : <span className={Style.value}>{this.state.bl ? this.state.bl : '/'}</span>}
                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Other: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.other ? this.state.other : '/'}</span> : <span className={Style.value}>{this.state.info ? this.state.bl : '/'}</span>}
                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Voyage: </span>
                                {this.props.isOrder ? <span className={Style.value}>{this.state.voyage ? this.state.voyage : '/'}</span> : <span className={Style.value}>{this.state.voyage ? this.state.voyage : '/'}</span>}
                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Transport company: </span>
                                <span className={Style.value}>{this.state.transportCompany ? this.state.transportCompany.name : '/'}</span>
                            </div>
                            <div className={Style.metaWrapper}>
                                <span className={Style.label}>Transport ID: </span>
                                {this.transportMode()}
                            </div>

                            {this.props.transportType == 'vessel' ? (<div className={Style.metaWrapper}>
                                <span className={Style.label}>Transport Id: </span>
                                <span className={Style.value}>{this.state.transportId ? this.state.transportIdName : this.props.transportId}</span>
                            </div>) : ('')}

                            <div className={'d-flex justify-content-end align-items-bottom ms-auto'}>
                                <div className={"mt-auto"}>
                                    <GalleryModal count={this.state.imagesCount} updateCount={this.fetchCargoData} itemid={this.state.id} />
                                </div>
                                <div className={"ms-auto mt-auto"}>
                                    <GalleryModal count={this.state.damageImageCount} updateCount={this.fetchCargoData} itemid={this.state.id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.checkIfBulkToggleEnabled() ? "accordion-collapse collapse show" : "accordion-collapse collapse"}>
                        <button className={'btn btn-danger mt-3'} onClick={this.bulkDeleteDamages}>Damage bulk delete</button>
                        <div className="">
                            <SimpleReactLightbox>
                                <div className={"layout-grid-1 damages-outer"}>
                                    <ExistingDamagesWrapper sendDamages={this.sendDamages} damageSetData={this.props.damageSetData} refreshDamages={this.refreshDamages} refreshCargoData={this.refreshCargoData} removeNewDamage={this.removeNewDamage} damages={this.state.damages} cargoItemId={this.props.itemid} orderId={this.state.orderId} />
                                    <DamagesWrapper damageSetData={this.props.damageSetData} tempDamages={this.state.tempDamages} refreshDamages={this.refreshDamages} refreshCargoData={this.refreshCargoData} removeNewDamage={this.removeNewDamage} damages={this.state.damages} cargoItemId={this.props.itemid} orderId={this.props.orderid} />
                                </div>
                            </SimpleReactLightbox>
                            <div className="d-flex justify-content-end mt-3">
                                <button onClick={() => { this.addNewDamage() }} className={"btn-min text-secondary"}>Add new damage</button>
                            </div>
                            <div className={"mt-3 row"}>
                                <div className="col-12 col-md-6">
                                    <div className={Style.metaWrapper}>
                                        <span className={Style.label + " mb-1"}>Info: </span>
                                        <span className={Style.value}>{this.state.info ? this.state.info : '/'}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={Style.metaWrapper}>
                                        <span className={Style.label + " mb-1"}>Documents: </span>
                                        <Attachments
                                            refreshCargoData={this.fetchCargoData}
                                            attachments={this.state.attachments}
                                            itemid={this.state.id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id={"item-bot-" + this.props.itemid} />

                            <div className="mt-2">
                                <div>
                                    <Link to={'/dashboard/history/' + this.state.vin} className="btn-min text-secondary">View Cargo Item History</Link>
                                </div>
                                <div>
                                    {this.otherDamageImportButton(this.state.vin)}
                                    <div className={this.isImportDamagesToggleEnabled() ? "accordion-collapse collapse show" : "accordion-collapse collapse"}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Part</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Severity</th>
                                                    <th scope="col">Cause</th>
                                                    <th scope="col">Repair Mode</th>
                                                    <th scope="col">Class</th>
                                                    <th scope="col">Dln</th>
                                                    <th scope="col">Comment client</th>
                                                    <th scope="col">Comment inspectus</th>
                                                    <th scope="col">Dimensions</th>
                                                    <th scope="col">Order</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.itemOtherDamages.map((item, index) => {
                                                return (<tr key={index}>
                                                    <td>{item.ds_part_name}</td>
                                                    <td>{item.ds_type_name}</td>
                                                    <td>{item.ds_severity_name}</td>
                                                    <td>{item.ds_cause_name}</td>
                                                    <td>{item.ds_repair_mode_name}</td>
                                                    <td>{item.ds_class}</td>
                                                    <td>{item.ds_dln}</td>
                                                    <td>{item.client_comment}</td>
                                                    <td>{item.inspectus_comment}</td>
                                                    <td>{item.dimensions}</td>
                                                    <td>{item.id_number}</td>
                                                    <td><button onClick={() => this.importDamage(item.id)} className="btn-min text-secondary">Import</button></td>
                                                </tr>)
                                            })}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    {this.pvReportButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CargoItem.propTypes = {};

export default withRouter(CargoItem);
